<template>
    <div class="talk">
        <div
                :style="{ 'background-image': 'url(' + data.background + ')' }"
                class="talk-container"
        >
            <div class="talk-inner">
                <div class="talk-inputs">
                    <h2>{{ data.title }}</h2>
                    <v-text-field
                            v-model="name"
                            label="Name"
                            outlined
                            required
                    ></v-text-field>
                    <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="Email address"
                            outlined
                            required
                    ></v-text-field>
                    <v-textarea
                            outlined
                            v-model="message"
                            name="Message"
                            label="Message"
                    ></v-textarea>
                    <v-btn v-on:click="sendEmail({'name':name,'email':email,'msg':message})">Submit</v-btn>
                </div>
                <div class="img-container d-none d-md-flex">
                    <img :src="data.img" alt="contact-img">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';
    export default {
        props: [
            'data'
        ],
        data() {
            return {
                name: '',
                email: '',
                message: '',
                emailRules: [
                    v => /.+@.+/.test(v) || 'E-mail must be valid',
                ]
            }
        },
        methods: {
            sendEmail(emailInformation) {                                           
            emailjs.send('service_mfnyp4x', 'ddEmailTemplate', {'to_name':'jay@type27.ca','from_name':emailInformation.email,'message':emailInformation.msg}, 'user_vEZiBsoiInTQZnHNyeL9r')
                .then((result) => {
                    console.log('SUCCESS!', result.text);
                }, (error) => {
                    console.log('FAILED...', error.text);
                });
            }
        },
    }
</script>