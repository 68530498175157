<template>
    <div class="services-card">
        <div class="card-container">
            <div class="img-container">
                <img :src="data.img" alt="card-img">
            </div>
            <h3>{{ data.title }}</h3>
            <p>{{ data.subtitle }}</p>
            <a href="javascript:void(0)" @click="learnMore(data.href)">Learn More ></a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        methods: {
            learnMore (route) {
                this.$router.go(this.$router.push({ name: route }));
                this.$store.commit('header/setTab', route);
            }
        }
    }
</script>