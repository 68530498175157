<template>
    <div class="banner">
        <div
                class="banner-inner"
                :style="{'background-image': 'url(' + data.image + ')'}"
        >
            <v-row class="d-none d-md-flex">
                <v-col
                        class="card-container"
                        cols="12"
                        lg="5"
                        md="7"
                >
                    <BannerCard :data="data" />
                </v-col>
            </v-row>
        </div>
        <BannerCard class="d-md-none" :data="data"/>
    </div>
</template>

<script>
    import BannerCard from './cards/banner-card'
    export default {
        props: ['data'],
        components: {
            BannerCard
        },
    }
</script>