<template>
    <div class="about">
        <Banner :data="bannerData" />
        <Standard :data="standardData" class="mt-12 mt-md-16" />
        <Brands :data="brandsData" class="mt-12 mt-md-16" />
        <Talk :data="talkData" class="my-12 my-md-16" />
    </div>
</template>

<script>
    import Talk from '../components/talk'
    import Brands from '../components/brands'
    import Standard from '../components/standard'
    import Banner from '../components/banner'
    export default {
        components: {
            Talk,
            Brands,
            Standard,
            Banner
        },
        data() {
            return {
                talkData: {
                    background: require('../../public/assets/images/unsplash_ueJ2oJeEK-U.png'),
                    img: require('../../public/assets/images/unsplash_v4bCFqVM9Gs.png'),
                    title: "Let's Talk"
                },
                brandsData: [
                    require('../../public/assets/icons/image-5.svg'),
                    require('../../public/assets/icons/image-6.svg'),
                    require('../../public/assets/icons/image-7.svg'),
                    require('../../public/assets/icons/image-8.svg'),
                    require('../../public/assets/icons/image-9.svg'),
                    require('../../public/assets/icons/image-10.svg'),
                    require('../../public/assets/icons/image-26.svg'),
                    require('../../public/assets/icons/image-27.svg'),
                    require('../../public/assets/icons/image-19.svg'),
                    require('../../public/assets/icons/image-13.svg'),
                    require('../../public/assets/icons/image-11.svg'),
                    require('../../public/assets/icons/image-23.svg'),
                    require('../../public/assets/icons/image-22.svg'),
                    require('../../public/assets/icons/image-17.svg'),
                    require('../../public/assets/icons/image-24.svg'),
                    require('../../public/assets/icons/image-18.svg'),
                    require('../../public/assets/icons/image-14.svg'),
                    require('../../public/assets/icons/image-12.svg'),
                    require('../../public/assets/icons/image-15.svg'),
                    require('../../public/assets/icons/image-20.svg'),                    
                ],
                standardData: {
                    title: 'The Type27 Standard',
                    cards: [
                        {
                            title: 'Unique',
                            text: 'Unique vision, unique creations, unique solutions.',                            
                            img: require('../../public/assets/icons/uniqueStandardIcon.svg')
                        },
                        {
                            title: 'Reliable',
                            text: 'Not just products, a brand you can trust! ',
                            img: require('../../public/assets/icons/reliabilityStandardIcon.svg')
                        },
                        {
                            title: 'High quality',
                            text: 'Creations that combine comfort and luxury with durability.',
                            img: require('../../public/assets/icons/qualityStandardIcon.svg')
                        }
                    ]
                },
                bannerData: {
                    image: require('../../public/assets/images/unsplash_IQbC4VU4YPQ.png'),
                    title: 'We are Type27',
                    text: 'Hotel Furniture. Custom Millwork. High-end Kitchens. Manufacturers & Suppliers',
                    href: 'contact'
                },
            }
        },
        created() {
            this.$store.commit('header/setTab', 'about');
        }
    }
</script>