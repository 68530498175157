<template>
    <div class="footer">
        <v-row>
            <v-col
                    cols="12"
                    md="5"
            >
                <div class="logo-container">
                    <img src="/assets/icons/logo.svg" alt="logo">
                </div>
                <div class="email-container">
                    <p>Sign up for our newsletter for special offers</p>
                    <div class="email-container-inner">
                        <v-text-field
                                v-model="email"
                                :rules="emailRules"
                                label="Email address"
                                outlined
                        ></v-text-field>
                        <v-btn>Submit</v-btn>
                    </div>
                </div>
            </v-col>
            <v-col
                    cols="12"
                    md="4"
            >
                <h4>Navigation</h4>
                <div class="footer-nav">
                    <a href="javascript:void(0)" @click="activator('home')">Home</a>
                    <a href="javascript:void(0)" @click="activator('services')">Services</a>
                    <a href="javascript:void(0)" @click="activator('about')">About Us</a>
                    <a href="javascript:void(0)" @click="activator('contact')">Contact Us</a>
                </div>
            </v-col>
            <v-col
                    cols="12"
                    md="3"
            >
                <h4>Questions?</h4>
                <div class="phone-container footer-contact">
                    <p class="title1">Phone</p>
                    <p>{{ contact.phone }}</p>
                </div>
                <div class="email footer-contact">
                    <p class="title1">Email</p>
                    <p>{{ contact.email }}</p>
                </div>
                <div class="social-container footer-contact">
                    <p class="title1">Social</p>
                    <div class="social-inner">
                        <a href="https://www.instagram.com/type27_inc/?hl=en">
                            <v-icon class="ml-0">mdi-instagram</v-icon>
                        </a>
                        <a href="#">
                            <v-icon>mdi-facebook</v-icon>
                        </a>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                contact: {
                    phone: '+1 (647) 527-9427',
                    email: 'jay@type27.ca'
                },
                email: '',
                emailRules: [
                    v => /.+@.+/.test(v) || 'E-mail must be valid',
                ]
            }
        },
        methods: {
            activator (route) {
                this.$router.go(this.$router.push({ name: route }));
                this.$store.commit('header/setTab', route);
            }
        }
    }
</script>