<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="header">
        <div class="logo-container">
            <a href="/">
                <img src="assets/icons/logo.svg" alt="logo">
            </a>
        </div>
        <div class="navigation">
            <div class="desktop-nav d-none d-sm-flex">
                <a href="javascript:void(0)" @click="activator('home')" class="home-tab tab-active" id="home">Home</a>
                <v-menu
                        open-on-hover
                        offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                class="services-btn services-tab"
                                id="services"
                                v-bind="attrs"
                                v-on="on"
                                @click="activator('services')"
                        >
                            <div>
                                <p>Services</p>
                                <v-icon id="chevron">mdi-chevron-down</v-icon>
                            </div>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                                v-for="(item, index) in items"
                                :key="index"
                                link
                        >
                            <v-list-item-title @click="activator(item.href)">{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <a href="javascript:void(0)" @click="activator('about')" class="about-tab" id="about">About</a>
            </div>
            <div @click="activator('contact')" class="contact-tab" id="contact">
                <v-btn class="contact-us-btn">Contact Us</v-btn>
            </div>
            <v-btn @click="showPanel" class="menu-btn d-sm-none">
                <v-icon>mdi-menu</v-icon>
            </v-btn>
            <slideout-panel></slideout-panel>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    Vue.component("MobileMenu", require("./mobile-menu").default);
    export default {
        data() {
            return {
                items: [
                    {
                        title: 'Hotel Furniture',
                        href: 'hotel'
                    },
                    {
                        title: 'Restaurant Millwork',
                        href: 'restaurant'
                    },
                    {
                        title: 'High End Kitchens',
                        href: 'kitchens'
                    },
                    {
                        title: 'Custom House Millwork',
                        href: 'house'
                    }
                ],
            }
        },
        methods: {
            showPanel() {
                const panelResult = this.$showPanel({
                    component: 'MobileMenu',
                    openOn: 'left',
                    props: {}
                });
                // eslint-disable-next-line no-unused-vars
                panelResult.promise.then(result =>{})
            },
            activator(route, path) {
                if (path !== route && path !== undefined) {
                    if (path === '') {
                        route = 'home';
                    } else {
                        route = path;
                    }
                }
                this.$router.push({ name: route }).catch(()=>{});
                if (route === 'hotel' || route === 'restaurant' || route === 'house' || route === 'kitchens') {
                    route = 'services';
                }
                this.$store.commit('header/setTab', route);
                let tabs = [
                  'home',
                  'about',
                  'services',
                  'contact'
                ];
                for (let i=0; i<tabs.length; i++) {
                    document.getElementById(tabs[i]).classList.remove('tab-active');
                }
                document.getElementById(route).classList.add('tab-active');
            }
        },
        mounted() {
            let pathName = window.location.pathname.split('/');
            let path = pathName[1];
            this.activator(this.$store.getters['header/getTab'](), path);
            window.addEventListener('popstate', function() {
                window.location.reload()
            });
        }
    }
</script>