<template>
    <div class="brands">
        <v-row class="brands-container">
            <v-col
                    v-for="(item, index) in data"
                    :key="index"
                    class="brands-img"
                    cols="5"
                    md="3"
                    sm="4"
                    xs="6"
            >
                <img :src="item" alt="logo">
            </v-col>

        </v-row>
    </div>
</template>

<script>
    export default {
        props: ['data'],
    }
</script>