<template>
    <div class="standard-card">
        <div class="card-header">
            <img :src="data.img" :alt="data.img">            
            <h3>{{ data.title }}</h3>
        </div>
        <div class="card-body">
            <p>{{ data.text }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data']
    }
</script>