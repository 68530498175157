<template>
    <div class="our-services">
        <h2>Our Services</h2>
        <v-row class="services-container">
            <v-col
                    v-for="(item, index) in data"
                    :key="index"
                    class="card-container"
                    cols="12"
                    md="3"
                    sm="6"
            >
                <ServicesCard :data="item" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import ServicesCard from './cards/services-card'
    export default {
        components: {
            ServicesCard
        },
        props: ['data'],
    }
</script>