<template>
    <div class="home">
        <BannerSlider :data="bannerSliderData" />
        <AboutBrand :data="aboutData" class="mt-12 mt-md-16" />
        <Standard :data="standardData" class="mt-12 mt-md-16" />
        <Services :data="servicesData" class="mt-12 mt-md-16" />
        <Brands :data="brandsData" class="mt-12 mt-md-16" />
        <Slider :data="sliderData" class="mt-12 mt-md-16" />
        <Talk :data="talkData" class="my-12 my-md-16" />
    </div>
</template>

<script>
    import Talk from '../components/talk'
    import Brands from '../components/brands'
    import Services from '../components/our-services'
    import Standard from '../components/standard'
    import AboutBrand from '../components/about-brand'
    import Slider from '../components/slider'
    import BannerSlider from '../components/banner-slider'
    export default {
        components: {
            Talk,
            Brands,
            Services,
            Standard,
            AboutBrand,
            Slider,
            BannerSlider
        },
        data() {
            return {
                talkData: {
                    background: require('../../public/assets/images/unsplash_ueJ2oJeEK-U.png'),
                    img: require('../../public/assets/images/unsplash_v4bCFqVM9Gs.png'),
                    title: "Let's Talk"
                },
                brandsData: [
                    require('../../public/assets/icons/image-5.svg'),
                    require('../../public/assets/icons/image-6.svg'),
                    require('../../public/assets/icons/image-7.svg'),
                    require('../../public/assets/icons/image-8.svg'),
                    require('../../public/assets/icons/image-9.svg'),
                    require('../../public/assets/icons/image-10.svg'),
                    require('../../public/assets/icons/image-26.svg'),
                    require('../../public/assets/icons/image-27.svg'),
                    require('../../public/assets/icons/image-19.svg'),
                    require('../../public/assets/icons/image-13.svg'),
                    require('../../public/assets/icons/image-11.svg'),
                    require('../../public/assets/icons/image-23.svg'),
                    require('../../public/assets/icons/image-22.svg'),
                    require('../../public/assets/icons/image-17.svg'),
                    require('../../public/assets/icons/image-24.svg'),
                    require('../../public/assets/icons/image-18.svg'),
                    require('../../public/assets/icons/image-14.svg'),
                    require('../../public/assets/icons/image-12.svg'),
                    require('../../public/assets/icons/image-15.svg'),
                    require('../../public/assets/icons/image-20.svg'),
                ],
                servicesData: [
                    {
                        img: require('../../public/assets/images/unsplash_p3UWyaujtQo.png'),
                        title: 'Hotel Funiture',
                        subtitle: 'A unique and comprehensive range of high-quality hotel furniture.',
                        href: 'hotel'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_3wylDrjxH-E.png'),
                        title: 'House Millwork',
                        subtitle: 'Beautiful and unique custom residential millwork.',
                        href: 'house'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_3ouDpvH1aO8.png'),
                        title: 'Restuarant Millwork',
                        subtitle: 'Top-quality craftsmanship personalized for unique spaces.',
                        href: 'restaurant'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_MkTh65JGIlU.png'),
                        title: 'High End Kitchens',
                        subtitle: 'Modern, innovative and functional high-end kitchen solutions.',
                        href: 'kitchens'
                    }
                ],
                standardData: {
                    title: 'The Type27 Standard',
                     cards: [
                        {
                            title: 'Unique',
                            text: 'Unique vision, unique creations, unique solutions.',                            
                            img: require('../../public/assets/icons/uniqueStandardIcon.svg')
                        },
                        {
                            title: 'Reliable',
                            text: 'Not just products, a brand you can trust! ',
                            img: require('../../public/assets/icons/reliabilityStandardIcon.svg')
                        },
                        {
                            title: 'High quality',
                            text: 'Creations that combine comfort and luxury with durability.',
                            img: require('../../public/assets/icons/qualityStandardIcon.svg')
                        }
                    ]
                },
                aboutData: {
                    image: require('../../public/assets/images/unsplash_Uxqlfigh6oE.png'),
                    title: 'About Us',
                    text1: 'While most would say they started their brand with a mission, we at Type27 started ours with a passion - a passion to create unique and reliable furniture and millwork solutions that would make any space come alive.',
                    text2: "If you're looking for unique, beautiful and smartly-designed hotel furniture solutions, high-end kitchen solutions, or custom mill-work for a hotel, restaurant, cafe, bar, retail space or your home, you've come to the right place",
                    href: 'about'
                },
                sliderData: [
                    require('../../public/assets/images/unsplash_Dl6H4_nzBpE.png'),
                    require('../../public/assets/images/unsplash_M8iGdeTSOkg.png'),
                    require('../../public/assets/images/unsplash_M8iGdeTSOkgC4.png'),
                    require('../../public/assets/images/unsplash_G7sE2S4Lab4.png')
                ],
                bannerSliderData: [
                    {
                        image: require('../../public/assets/images/unsplash_Yrxr3bsPdS0.png'),
                        title: 'We Are Type 27',
                        text: 'Hotel Furniture. Custom Millwork. High-end Kitchens. Manufacturers & Suppliers',
                        href: 'contact'
                    }                    
                ],
            }
        },
        created() {
            this.$store.commit('header/setTab', 'home');
        }
    }
</script>