// initial state
const state = () => {
    return {
        tab: "",
    };
};

// getters
const getters = {
    getTab: state => () => {
        return state.data;
    }
};

// mutation
const mutations = {
    setTab(state, data) {
        state.data = data;
    },
};

//actions
const actions = {
};

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}
