<template>
    <div class="hotel">
        <ServicesTitle :data="titleData" class="mt-12 mt-md-16" />
        <v-breadcrumbs
                :items="items"
                class="mt-12 mt-md-16"
                divider="/"
        ></v-breadcrumbs>
        <CardsContainer :data="cardsData" class="mt-12 mt-md-16" />
        <Slider :data="sliderData" class="mt-12 mt-md-16" />
        <Talk :data="talkData" class="my-12 my-md-16" />
    </div>
</template>

<script>
    import Slider from '../components/slider'
    import Talk from '../components/talk'
    import ServicesTitle from '../components/services-title'
    import CardsContainer from '../components/cards/cards-container'
    export default {
        components: {
            Talk,
            Slider,
            ServicesTitle,
            CardsContainer
        },
        data() {
            return {
               sliderData: [
                    require('../../public/assets/images/unsplash_Dl6H4_nzBpE.png'),
                    require('../../public/assets/images/unsplash_M8iGdeTSOkg.png'),
                    require('../../public/assets/images/unsplash_M8iGdeTSOkgC4.png'),
                    require('../../public/assets/images/unsplash_G7sE2S4Lab4.png')

                ],
                talkData: {
                    background: require('../../public/assets/images/unsplash_ueJ2oJeEK-U.png'),
                    img: require('../../public/assets/images/unsplash_v4bCFqVM9Gs.png'),
                    title: "Let's Talk"
                },
                titleData: {
                    title: 'Hotel Furniture + Casegoods',
                    text1: 'Type27 are proud manufacturers and suppliers of a wide and comprehensive range of high-quality hotel and hospitality furniture.',
                    text2: 'We specialize in supplying furniture to boutique hotels, catering to a plethora of themes – from simple and modern to rustic and luxurious, and everything in between.'
                },
                items: [
                    {
                        text: 'Products',
                        disabled: true,
                        href: '#'
                    },
                    {
                        text: 'Services',
                        disabled: false,
                        href: '/services'
                    }
                ],
                cardsData: [
                    {
                        img: require('../../public/assets/images/unsplash_B8WIgxA_PFU.png'),
                        title: 'Closets',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_3h7jbWXhpgM.png'),
                        title: 'Mini Bars',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_LBF83MEwQyY.png'),
                        title: 'Headboards',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_zukdSYdFB_A.png'),
                        title: 'Luggage Bench',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_Wj6oZNCAzs4.png'),
                        title: 'Kitchenette',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_zSgzGRdJmMY.png'),
                        title: 'Night Stands',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_LBF83MEwQyYz.png'),
                        title: 'Vanity',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_EzoCn-yj8jo.png'),
                        title: 'Dining Table',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_SoIVYqEgnRQZ.png'),
                        title: 'Combo Units',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_zSgzGRdJmMYz.png'),
                        title: 'TV Panel Unit',
                        href: '#'
                    },
                   
                    {
                        img: require('../../public/assets/images/unsplash_8NxTrV6i4WQ.png'),
                        title: 'Coffee Tables',
                        href: '#'
                    }
                ],
            }
        },
        created() {
            this.$store.commit('header/setTab', 'hotel');
        }
    }
</script>