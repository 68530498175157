<template>
    <div class="contact-us">
        <Talk :data="talkData" class="mt-12 mt-sm-0" />
        <div class="map my-12 my-md-16">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.318505265948!2d-79.61884138449992!3d43.72470987911881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3bb09d65d65b%3A0x6138cebf63b7db33!2s700%20Humberwood%20Blvd%20%23924%2C%20Etobicoke%2C%20ON%20M9W%207J4!5e0!3m2!1sen!2sca!4v1648653526128!5m2!1sen!2sca" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
</template>

<script>
    import Talk from '../components/talk'
    export default {
        components: {
            Talk
        },
        data() {
            return {
                talkData: {
                    background: require('../../public/assets/images/unsplash_ueJ2oJeEK-U.png'),
                    img: require('../../public/assets/images/unsplash_v4bCFqVM9Gs.png'),
                    title: "Let's Talk"
                },
            }
        },
        created() {
            this.$store.commit('header/setTab', 'contact');
        }
    }
</script>