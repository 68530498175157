<template>
  <v-app>
    <v-main>
      <Header v-if="$route.meta.header !== false"/>
      <router-view/>
      <Footer v-if="$route.meta.footer !== false"/>
    </v-main>
  </v-app>
</template>

<script>
import Header from './components/header'
import Footer from './components/footer'
export default {
  name: 'App',

  components: {
    Header,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
  @import '../public/assets/scss/style.scss';
  @import '../public/assets/scss/responsive.scss';
</style>

