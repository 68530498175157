<template>
    <div class="services-profile">
        <v-row>
            <v-col
                    class="image-container"
                    cols="12"
                    lg="3"
                    sm="5"
            >
                <img :src="data.image" alt="profile-img">
            </v-col>
            <v-col
                    class="detail-container"
                    cols="12"
                    lg="9"
                    sm="7"
            >
                <h2>{{ data.title }}</h2>
                <p>{{ data.text }}</p>
                <v-btn @click="samples(data.href)">View Samples</v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        methods: {
            samples(route) {
                this.$router.go(this.$router.push({ name: route }));
                this.$store.commit('header/setTab', route);
            }
        }
    }
</script>