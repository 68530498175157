<template>
    <div class="slider">
        <Splide class="slider-inner" :options="options">
            <SplideSlide class="slider-slide" v-for="(item, index) in data" :key="index">
                <img :src="item" alt="slider-img">
            </SplideSlide>
        </Splide>
    </div>
</template>

<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide'
    import '@splidejs/splide/dist/css/splide.min.css'
    export default {
        components: {
            Splide,
            SplideSlide,
        },
        props: ['data'],
        data() {
            return {
                model: null,
                options: {
                    type: 'loop',
                    padding: '6rem',
                    pagination: false,
                    arrows: true,
                    breakpoints: {
                        1590: {
                            pagination: false,
                            arrows: true,
                        },
                        600: {
                            pagination: true,
                            arrows: false,
                            padding: '0'
                        }
                    }
                },
            }
        }
    }
</script>