<template>
    <div class="kitchens">
        <ServicesTitle :data="titleData" class="mt-12 mt-md-16" />
        <v-breadcrumbs
                :items="items"
                class="mt-12 mt-md-16"
                divider="/"
        ></v-breadcrumbs>
        <CardsContainer :data="cardsData" class="mt-12 mt-md-16" />
        <Slider :data="sliderData" class="mt-12 mt-md-16" />
        <Talk :data="talkData" class="my-12 my-md-16" />
    </div>
</template>

<script>
    import Slider from '../components/slider'
    import Talk from '../components/talk'
    import ServicesTitle from '../components/services-title'
    import CardsContainer from '../components/cards/cards-container'
    export default {
        components: {
            Talk,
            Slider,
            ServicesTitle,
            CardsContainer
        },
        data() {
            return {
              sliderData: [
                    require('../../public/assets/images/unsplash_Dl6H4_nzBpE.png'),
                    require('../../public/assets/images/unsplash_M8iGdeTSOkg.png'),
                    require('../../public/assets/images/unsplash_M8iGdeTSOkgC4.png'),
                    require('../../public/assets/images/unsplash_G7sE2S4Lab4.png')
                ],
                talkData: {
                    background: require('../../public/assets/images/unsplash_ueJ2oJeEK-U.png'),
                    img: require('../../public/assets/images/unsplash_v4bCFqVM9Gs.png'),
                    title: "Let's Talk"
                },
                titleData: {
                    title: 'High End Kitchens',
                    text1: 'Timeless designs, innovative ideas, expert craftsmanship that fuses form and function with your vision, and working closely with you every step of the way – your dream kitchen is waiting for you.',
                },
                items: [
                    {
                        text: 'Products',
                        disabled: true,
                        href: '#'
                    },
                    {
                        text: 'Services',
                        disabled: false,
                        href: '/services'
                    }
                ],
                cardsData: [
                    {
                        img: require('../../public/assets/images/unsplash_SoIVYqEgnRQu.png'),
                        title: 'Modular Kitchens',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_zSgzGRdJmMY1.png'),
                        title: 'Traditional Kitchens',
                        href: '#'
                    }
                ],
            }
        },
        created() {
            this.$store.commit('header/setTab', 'kitchens');
        }
    }
</script>