<template>
    <div class="services-title">
        <v-row class="title-inner">
            <v-col
                    class="main-title"
                    cols="12"
                    md="6"
            >
                <h1>{{ data.title }}</h1>
            </v-col>
            <v-col
                    class="text-title"
                    cols="12"
                    md="6"
            >
                <h4>{{ data.text1 }}</h4>
                <h4 v-if="data.text2 !==undefined" >{{ data.text2 }}</h4>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        props: ['data']
    }
</script>