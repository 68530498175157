<template>
    <v-row class="card-container-co">
        <v-col
                v-for="(item, index) in data"
                :key="index"
                class="cards-inner"
                cols="6"
                md="3"
                sm="4"
        >
            <Card :data="item" />
        </v-col>
    </v-row>
</template>

<script>
    import Card from './card'
    export default {
        components: {
            Card
        },
        props: ['data'],
    }
</script>