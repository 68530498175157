<template>
    <div class="banner-card">
        <div class="card-header">
            <h1>{{ data.title }}</h1>
        </div>
        <div class="card-body">
            <h4>{{ data.text }}</h4>
            <v-btn @click="contact(data.href)">Contact Us</v-btn>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        methods: {
            contact(route) {
                this.$router.go(this.$router.push({ name: route }));
                this.$store.commit('header/setTab', route);
            }
        }
    }
</script>