<template>
    <div class="restaurant">
        <ServicesTitle :data="titleData" class="mt-12 mt-md-16" />
        <v-breadcrumbs
                :items="items"
                class="mt-12 mt-md-16"
                divider="/"
        ></v-breadcrumbs>
        <CardsContainer :data="cardsData" class="mt-12 mt-md-16" />
        <Slider :data="sliderData" class="mt-12 mt-md-16" />
        <Talk :data="talkData" class="my-12 my-md-16" />
    </div>
</template>

<script>
    import Slider from '../components/slider'
    import Talk from '../components/talk'
    import ServicesTitle from '../components/services-title'
    import CardsContainer from '../components/cards/cards-container'
    export default {
        components: {
            Talk,
            Slider,
            ServicesTitle,
            CardsContainer
        },
        data() {
            return {
                sliderData: [
                    require('../../public/assets/images/unsplash_Dl6H4_nzBpE.png'),
                    require('../../public/assets/images/unsplash_M8iGdeTSOkg.png'),
                    require('../../public/assets/images/unsplash_M8iGdeTSOkgC4.png'),
                    require('../../public/assets/images/unsplash_G7sE2S4Lab4.png')
                ],
                talkData: {
                    background: require('../../public/assets/images/unsplash_ueJ2oJeEK-U.png'),
                    img: require('../../public/assets/images/unsplash_v4bCFqVM9Gs.png'),
                    title: "Let's Talk"
                },
                titleData: {
                    title: 'Restaurant  Millwork',
                    text1: 'No matter what kind of retail or restaurant space you own, millwork is a great option to enhance your interiors.',
                    text2: "We provide end-to-end custom millwork solutions, as well as inhouse conceptual design services, for Retail spaces, Restaurants, Cafes and Bars. Each and every design and installation of ours is unique and manufactured specifically to the customer's requirement"
                },
                items: [
                    {
                        text: 'Products',
                        disabled: true,
                        href: '#'
                    },
                    {
                        text: 'Services',
                        disabled: false,
                        href: '/services'
                    }
                ],
                cardsData: [
                    {
                        img: require('../../public/assets/images/unsplash_SoIVYqEgnRQi.png'),
                        title: 'Franchise Design Millwork',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_zSgzGRdJmMYi.png'),
                        title: 'Custom Restaurant Millwork',
                        href: '#'
                    }
                ],
            }
        },
        created() {
            this.$store.commit('header/setTab', 'restaurant');
        }
    }
</script>