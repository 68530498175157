<template>
    <div class="mobile-menu">
        <div class="mobile-menu-header">
            <div class="menu-header-logo">
                <img src="assets/icons/logo.svg" alt="logo">
            </div>
            <v-btn @click="close" class="close-btn">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <div class="mobile-menu-body">
            <a href="javascript:void(0)" @click="activator('home')">Home</a>
            <a href="javascript:void(0)" @click="activator('about')">About</a>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Services
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div class="products-items">
                            <v-btn @click="activator('hotel')" class="item">Hotel Furniture</v-btn>
                            <v-btn @click="activator('restaurant')" class="item">Restaurant Millwork</v-btn>
                            <v-btn @click="activator('kitchens')" class="item">High End Kitchens</v-btn>
                            <v-btn @click="activator('house')" class="item">Custom House Millwork</v-btn>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <a href="javascript:void(0)" @click="activator('contact')" >Contact Us</a>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            close() {
                this.$emit('closePanel', {});
            },
            activator(route) {
                this.$router.go(this.$router.push({ name: route }));
            }
        }
    }
</script>