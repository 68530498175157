<template>
    <div class="about-brand">
        <v-row class="about-brand-inner">
            <v-col
                    class="image-container"
                    cols="12"
                    md="6"
            >
                <img :src="data.image" alt="card-image">
            </v-col>
            <v-col
                    class="details-container"
                    cols="12"
                    md="6"
            >
                <div class="details-inner">
                    <h2>{{ data.title }}</h2>
                    <div class="description">
                        <p>{{ data.text1 }}</p>
                        <p v-if="data.text2 !== undefined">{{ data.text2 }}</p>
                        <a :href="data.href">Learn More ></a>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        props: ['data']
    }
</script>