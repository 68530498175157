<template>
    <div class="services">
        <div class="services-inner pa-0 pa-md-16 pa-sm-10">
            <h1>Services</h1>
            <div class="services-profiles" v-for="(item, index) in profileData" :key="index">
                <ServicesProfile :data="item" class="my-6 my-md-10" ></ServicesProfile>
            </div>
        </div>
        <Brands :data="brandsData" class="mt-12 mt-md-16" />
        <Slider :data="sliderData" class="mt-12 mt-md-16" />
        <Talk :data="talkData" class="my-12 my-md-16" />
    </div>
</template>

<script>
    import ServicesProfile from '../components/cards/services-profile'
    import Talk from '../components/talk'
    import Brands from '../components/brands'
    import Slider from '../components/slider'
    export default {
        components: {
            ServicesProfile,
            Talk,
            Brands,
            Slider
        },
        data() {
            return {
                profileData: [
                    {
                        image: require('../../public/assets/images/unsplash_p3UWyaujtQo.png'),
                        title: 'Hotel Furniture',
                        text: 'Type27 are proud manufacturers and suppliers of a wide and comprehensive range of high-quality hotel and hospitality furniture. We specialize in supplying furniture to boutique hotels, catering to a plethora of themes – from simple and modern to rustic and luxurious, and everything in between.',
                        href: 'hotel'
                    },
                    {
                        image: require('../../public/assets/images/unsplash_3ouDpvH1aO8.png'),
                        title: 'Restaurant Millwork',
                        text: "No matter what kind of retail or restaurant space you own, millwork is a great option to enhance your interiors. We provide end-to-end custom millwork solutions, as well as inhouse conceptual design services, for Retail spaces, Restaurants, Cafes and Bars. Each and every design and installation of ours is unique and manufactured specifically to the customer's requirement.",
                        href: 'restaurant'
                    },
                    {
                        image: require('../../public/assets/images/unsplash_MkTh65JGIlU.png'),
                        title: 'High End Kitchens',
                        text: "With years of hands-on experience in designing and manufacturing high-end kitchens, we understand that the kitchen is the heart of your space, and so it is essential to get each and every detail right. We provide end-to-end solutions, including inhouse design rendering, conceptual design, and custom manufacturing using only the most skilled craftsmen and the best quality materials. ",
                        href: 'kitchens'
                    },
                    {
                        image: require('../../public/assets/images/unsplash_3wylDrjxH-E.png'),
                        title: 'Custom House Millwork',
                        text: "We at Type27 specialize in the design and creation of some of the industry's finest millwork solutions for your home. Whether you're looking to enhance the interior or the exterior of your space, our expert designers and skilled craftsmen will work with you closely to create unique solutions that fit your specific requirement.",
                        href: 'house'
                    }
                ],
                talkData: {
                    background: require('../../public/assets/images/unsplash_ueJ2oJeEK-U.png'),
                    img: require('../../public/assets/images/unsplash_v4bCFqVM9Gs.png'),
                    title: "Let's Talk"
                },
                brandsData: [
                    require('../../public/assets/icons/image-5.svg'),
                    require('../../public/assets/icons/image-6.svg'),
                    require('../../public/assets/icons/image-7.svg'),
                    require('../../public/assets/icons/image-8.svg'),
                    require('../../public/assets/icons/image-9.svg'),
                    require('../../public/assets/icons/image-10.svg'),
                    require('../../public/assets/icons/image-26.svg'),
                    require('../../public/assets/icons/image-27.svg'),
                    require('../../public/assets/icons/image-19.svg'),
                    require('../../public/assets/icons/image-13.svg'),
                    require('../../public/assets/icons/image-11.svg'),
                    require('../../public/assets/icons/image-23.svg'),
                    require('../../public/assets/icons/image-22.svg'),
                    require('../../public/assets/icons/image-17.svg'),
                    require('../../public/assets/icons/image-24.svg'),
                    require('../../public/assets/icons/image-18.svg'),
                    require('../../public/assets/icons/image-14.svg'),
                    require('../../public/assets/icons/image-12.svg'),
                    require('../../public/assets/icons/image-15.svg'),
                    require('../../public/assets/icons/image-20.svg'),
                ],
               sliderData: [
                    require('../../public/assets/images/unsplash_Dl6H4_nzBpE.png'),
                    require('../../public/assets/images/unsplash_M8iGdeTSOkg.png'),
                    require('../../public/assets/images/unsplash_M8iGdeTSOkgC4.png'),
                    require('../../public/assets/images/unsplash_G7sE2S4Lab4.png')
                ],
            }
        },
        created() {
            this.$store.commit('header/setTab', 'services');
        }
    }
</script>