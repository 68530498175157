import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home'
import services from '../views/services'
import hotel from '../views/hotel-furniture'
import restaurant from '../views/restaurant-millwork'
import house from '../views/house-millwork'
import kitchens from '../views/kitchens'
import about from '../views/about'
import contact from '../views/contact-us'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: home,
    },
    {
        path: '/services',
        name: 'services',
        component: services,
    },
    {
        path: '/hotel',
        name: 'hotel',
        component: hotel,
    },
    {
        path: '/restaurant',
        name: 'restaurant',
        component: restaurant,
    },
    {
        path: '/house',
        name: 'house',
        component: house,
    },
    {
        path: '/kitchens',
        name: 'kitchens',
        component: kitchens,
    },
    {
        path: '/about',
        name: 'about',
        component: about,
    },
    {
        path: '/contact',
        name: 'contact',
        component: contact,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
export default router