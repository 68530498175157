<template>
    <div class="standard">
        <div class="standard-header">
            <h2>{{ data.title }}</h2>
        </div>
        <v-divider></v-divider>
        <div class="standard-body">
            <v-row>
                <v-col
                        v-for="(item, index) in data.cards" :key="index"
                        cols="12"
                        md="4"
                >
                    <StandardCard :data="item" />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import StandardCard from '../components/cards/standard-card'
    export default {
        components: {
            StandardCard
        },
        props: ['data'],
    }
</script>