<template>
    <div class="card-co">
        <a :href="data.href">
            <v-sheet>
                <div class="img-container">
                    <img :src="data.img" alt="card-img">
                </div>
                <div class="detail">
                    <h4>{{ data.title }}</h4>
                </div>
            </v-sheet>
        </a>
    </div>
</template>

<script>
    export default {
        props: ['data']
    }
</script>