<template>
    <div class="house">
        <ServicesTitle :data="titleData" class="mt-12 mt-md-16" />
        <v-breadcrumbs
                :items="items"
                class="mt-12 mt-md-16"
                divider="/"
        ></v-breadcrumbs>
        <CardsContainer :data="cardsData" class="mt-12 mt-md-16" />
        <Slider :data="sliderData" class="mt-12 mt-md-16" />
        <Talk :data="talkData" class="my-12 my-md-16" />
    </div>
</template>

<script>
    import Slider from '../components/slider'
    import Talk from '../components/talk'
    import ServicesTitle from '../components/services-title'
    import CardsContainer from '../components/cards/cards-container'
    export default {
        components: {
            Talk,
            Slider,
            ServicesTitle,
            CardsContainer
        },
        data() {
            return {
                sliderData: [
                    require('../../public/assets/images/unsplash_Dl6H4_nzBpE.png'),
                    require('../../public/assets/images/unsplash_M8iGdeTSOkg.png'),
                    require('../../public/assets/images/unsplash_M8iGdeTSOkgC4.png'),
                    require('../../public/assets/images/unsplash_G7sE2S4Lab4.png')
                ],
                talkData: {
                    background: require('../../public/assets/images/unsplash_ueJ2oJeEK-U.png'),
                    img: require('../../public/assets/images/unsplash_v4bCFqVM9Gs.png'),
                    title: "Let's Talk"
                },
                titleData: {
                    title: 'Custom House Millwork',
                    text1: "We at Type27 specialize in the design and creation of some of the industry's finest millwork solutions for your home.",
                    text2: "Whether you're looking to enhance the interior or the exterior of your space, our expert designers and skilled craftsmen will work with you closely to create unique solutions that fit your specific requirement."
                },
                items: [
                    {
                        text: 'Products',
                        disabled: true,
                        href: '#'
                    },
                    {
                        text: 'Services',
                        disabled: false,
                        href: '/services'
                    }
                ],
                cardsData: [
                    {
                        img: require('../../public/assets/images/unsplash_SoIVYqEgnRQ1.png'),
                        title: 'Renovation',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_zSgzGRdJmMYq.png'),
                        title: 'High-End Millwork',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_LBF83MEwQyYw.png'),
                        title: 'Vanities',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_zukdSYdFB_Aa.png'),
                        title: 'Wardrobe',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_SoIVYqEgnRQp.png'),
                        title: 'Closets',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_zSgzGRdJmMYa.png'),
                        title: 'Kitchens',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_LBF83MEwQyYl.png'),
                        title: 'Living Rooms',
                        href: '#'
                    },
                    {
                        img: require('../../public/assets/images/unsplash_zukdSYdFB_A4.png'),
                        title: 'Family Rooms',
                        href: '#'
                    },
                ],
            }
        },
        created() {
            this.$store.commit('header/setTab', 'house');
        }
    }
</script>