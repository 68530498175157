<template>
    <div class="banner-slider">
        <Splide class="slider-container" :options="options">
            <SplideSlide class="slider-slide" v-for="(item, index) in data" :key="index">
                <div
                        class="slide-container"
                        :style="{'background-image': 'url(' + item.image + ')'}"
                >
                    <v-row class="d-none d-md-flex">
                        <v-col
                                class="card-container"
                                cols="12"
                                lg="5"
                                md="7"
                        >
                            <BannerCard :data="item" />
                        </v-col>
                    </v-row>
                </div>
            </SplideSlide>
        </Splide>
        <BannerCard class="d-md-none" :data="data[0]"/>
    </div>
</template>

<script>
    import { Splide, SplideSlide } from '@splidejs/vue-splide'
    import '@splidejs/splide/dist/css/splide.min.css'
    import BannerCard from './cards/banner-card'
    export default {
        components: {
            Splide,
            SplideSlide,
            BannerCard
        },
        props: ['data'],
        data() {
            return {
                options: {
                    type: 'loop',
                    arrows: false,
                }
            }
        }
    }
</script>